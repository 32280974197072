import Glide from '@glidejs/glide';
//eslint-disable-next-line no-unused-vars
export default Product => {

   class ProductModal {
      constructor(zoom, price) {
         this.modal = document.querySelector('.modal');
         this.zoom = zoom;
         this.price = price;

         this.status = true;
         this.bindEvents();
      }

      toggleCloseIcon() {

         const close = document.querySelector('.close');
         const open = document.querySelector('.open');

         const toggleIcons = () => {
            [open, close].forEach((icon) => {
               icon.classList.toggle('search-open');
               icon.classList.toggle('search-close');
               icon.classList.toggle('product-modal-open');
            });
         };

         toggleIcons();
      }

      moveElement(source, destination) {
         const fragment = document.createDocumentFragment();
         fragment.appendChild(source);
         destination.appendChild(fragment);

      }

      fillSpans() {
         const spans = Array.from(this.modal.querySelectorAll('.glide__counter span'));
         const slides = Array.from(this.modal.querySelectorAll('.glide__slide'));
         const current = this.modal.querySelector('.glide__slide--active');

         spans[0].innerHTML = slides.indexOf(current) + 1;
         spans[1].innerHTML = slides.length;
      }

      createCarouselCopy() {
         const carousel = document.querySelector('.glide-product');
         const copy = carousel.cloneNode(true);
         copy.classList.remove('glide-product');
         this.moveElement(copy, this.modal);
         copy.classList.add('glide-product-carousel');


         let glider = new Glide('.glide-product-carousel', {
            animationDuration: 1000,
            animationTimingFunc: 'ease-in',
         });
         glider.mount();

         this.fillSpans();

         glider.on('run.after', () => {
            this.fillSpans();
         });
      }

      bindEvents() {
         const close = document.querySelector('.close');
         const price = document.querySelector('.price');

         this.zoom.addEventListener('click', () => {

            this.modal.classList.add('open-modal');
            this.toggleCloseIcon();
            this.createCarouselCopy();
            const modal_zoom = this.modal.querySelector('.glide__zoom');
            modal_zoom.classList.add('hide');

         });

         this.price.addEventListener('click', () => {
            this.modal.classList.add('open-modal');
            this.toggleCloseIcon();
            this.moveElement(price, this.modal);
         });

         close.addEventListener('click', () => {
            const product = document.querySelector('.product');
            this.moveElement(price, product);
         });
      }
   }

   class FormHandler {
      constructor(form) {
         this.form = form;

         this.bindEvents();
      }

      async ajaxCall() {
         const url = window.location.href;
         const data = new FormData(this.form);
         const search_query = await fetch(url, {
            method: 'POST',
            header: {
               'X-Requested-With': 'XMLHttpRequest'
            },
            body: data
         });

         return await search_query.json();
      }

      ajaxResponse(response) {
         const submit = this.form.querySelector('input[type="submit"]');
         const sent = document.querySelector('.price__sent');
         sent.innerHTML =  response.sent ? 'Votre email a été envoyé!' : 'Oups, une erreur est survenue...';
         sent.classList.toggle('hide');
         submit.classList.add('hide');
      }

      checkMail() {
         const email = this.form.querySelector('input[name="email"]').value;
         return String(email)
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
      }

      errorHandling() {
         const error_log = document.querySelector('.price__error');
         const error = 'Entrez une adresse email valide svp!';
         error_log.innerHTML = error;
      }

      bindEvents() {
         this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            if (this.checkMail()) {
               const response = this.ajaxCall();
               response.then(res => {
                  this.ajaxResponse(res);
               });
            } else {
               this.errorHandling();
            }
         });
      }
   }

   const zoom = document.querySelector('.glide__zoom');
   const price = document.querySelector('.product__data__price span');

   const form = document.querySelector('.price__form');
   new ProductModal(zoom, price);
   new FormHandler(form);

   const product_slider = new Glide('.glide-product');

   setTimeout(() => {
      product_slider.mount();
  }, 50);

};
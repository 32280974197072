// eslint-disable-next-line no-unused-vars
export default Modal => {

    class ModalToggle {
        constructor(hamburger, modal) {
            this.hamburger = hamburger;
            this.modal = modal;

            this.navigation = document.querySelector('.navigation');
            this.header = document.querySelector('.header-wrapper');

            this.status = true;

            this.bindEvents();

            this.hideNavigation();
        }

        hideNavigation(){
            if (window.innerWidth < 768){
                this.moveNavigation(this.navigation, this.modal); 
                this.navigation.classList.add('hide');
            } else {
                this.moveNavigation(this.navigation, this.header);
                this.navigation.classList.remove('hide');
            }
        }


        moveNavigation(source, destination) {
            var fragment = document.createDocumentFragment();
            fragment.appendChild(source);
            destination.appendChild(fragment);
        }

        toggleNavigation(){
            if (this.navigation.classList.contains('hide')){
                this.navigation.classList.remove('hide');
            } else {
                this.navigation.classList.add('hide');
            }
        }


        bindEvents() {

            this.hamburger.addEventListener('click', () => {
                this.status ? this.modal.classList.add('open-modal') : this.modal.classList.remove('open-modal');
                this.status ? this.navigation.classList.add('hide') : this.navigation.classList.remove('hide');
                this.toggleNavigation();
                this.status = !this.status;
            });

            window.addEventListener('resize', () => {
                this.hideNavigation();
            });

        }

    }

    const hamburger = document.querySelector('.header__hamburger');
    const modal = document.querySelector('.modal');
    new ModalToggle(hamburger, modal);

};
//eslint-disable-next-line no-unused-vars
export default Legal => {

    var LEGAL = `<img src="/static/logos/legal__logo.png" alt="Le pictogramme d'un document légal (mentions légales)">
    <span>Mentions Légales</span>`;
    var SEARCH = `
    <img src="/static/logos/recherche_blanc__logo.png" alt="Le pictogramme d'une loupe (recherche)">
    <span>Recherche</span>`;

    class LegalToggle {
        constructor(legal_wrapper, legal_link, search) {
            this.legal_wrapper = legal_wrapper;
            this.legal_link = legal_link;
            this.search = search;
            this.search_results = document.querySelector('.search__results');

            this.status = true;

            this.bindEvents();

        }

        changeLinkContent() {
            this.status ? legal_link.innerHTML = SEARCH : legal_link.innerHTML = LEGAL;
        }

        bindEvents() {

            this.legal_link.addEventListener('click', () => {
                this.legal_wrapper.classList.toggle('hide');
                this.search.classList.toggle('hide');
                this.search_results.classList.toggle('hide');
                this.changeLinkContent();
                this.status = !this.status;
            });
        }
    }

    const legal_wrapper = document.querySelector('.search__legal');
    const legal_link = document.querySelector('.search__to-legal');
    const search = document.querySelector('.search__form');

    new LegalToggle(legal_wrapper, legal_link, search);



};
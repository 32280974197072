// eslint-disable-next-line no-unused-vars
export default Hamburger => {

  class HamburgerToggle {
    constructor(hamburger) {
      this.hamburger = hamburger;
      [this.top, this.center, this.bottom] = [...this.hamburger.querySelectorAll('span')];

      this.status = true;

      this.bindEvents();

    }

    preventSearchClick() {
      const search = document.querySelector('.header__right img:last-of-type');
      search.classList.toggle('no-click');
    }


    moveCenter() {
      this.status ? this.center.classList.add('move-center-out') : this.center.classList.remove('move-center-out');
      !this.status ? this.center.classList.add('move-center-in') : this.center.classList.remove('move-center-in');

    }

    bindEvents() {
      this.hamburger.addEventListener('click', () => {
        this.moveCenter();
        this.preventSearchClick();
        this.status ? this.top.classList.add('move-top') : this.top.classList.remove('move-top');
        this.status ? this.bottom.classList.add('move-bottom') : this.bottom.classList.remove('move-bottom');
        this.status ? this.hamburger.classList.add('focus') : this.hamburger.classList.remove('focus');
        this.status = !this.status;
      });
    }
  }

  const hamburger = document.querySelector('.header__hamburger');
  new HamburgerToggle(hamburger);

  const navigation_links = document.querySelectorAll('.navigation a');
  navigation_links.forEach((navigation__link) => {
    let link_name = navigation__link.innerHTML.toLowerCase().replace('é','e');
    const path = window.location.pathname.split('/')[1];
    if (path.includes(link_name)){
      navigation__link.classList.add('navigation-current');
    }
  });


};
import Glide from '@glidejs/glide';

//eslint-disable-next-line no-unused-vars
export default Carousel => {

    function changeUrl(a_element, link_large, link_small  ){
        window.innerWidth >= 768 ? a_element.href = link_large : a_element.href = link_small;
    }

    function addUrl() {
        const images = document.querySelectorAll('.glide__image');
        images.forEach((image) => {
            const link_small = image.dataset.md;
            const link_large = image.dataset.xl;
            const a_element = image.querySelector('a');

            changeUrl(a_element, link_large, link_small);

            window.addEventListener('resize', () => {
                changeUrl(a_element, link_large, link_small);
            });

        });
    }

    const home_slider = new Glide('.home', {
        type: 'carousel',
        autoplay: 3000,
        animationDuration: 1000,
        animationTimingFunc: 'ease-in-out',
    }).mount();

    setTimeout(() => {
        home_slider.mount();
        addUrl();
    }, 50);
};
//eslint-disable-next-line no-unused-vars
export default Video => {

    class VideoModalToggle {
        constructor(element) {
            this.element = element;
            this.status = true;

            this.modal = document.querySelector('.modal');
            this.video_player = document.querySelector('[data-player]');

            this.bindEvents();
        }

        toggleCloseIcon() {

            const close = document.querySelector('.close');
            const open = document.querySelector('.open');
   
            const toggleIcons = () => {
               [open, close].forEach((icon) => {
                  icon.classList.toggle('search-open');
                  icon.classList.toggle('search-close');
                  icon.classList.toggle('product-modal-open');
               });
            };
   
            toggleIcons();
         }

        moveVideoToModal(source, destination) {
            const fragment = document.createDocumentFragment();
            fragment.appendChild(source);
            destination.appendChild(fragment);
            this.populateVideoLinks(source);
        }

        populateVideoLinks(video) {
            const links = video.querySelectorAll('.source');
            links.forEach((link) => {
                if (link.nodeName == 'SOURCE'){
                    link.src = this.element.dataset.link;
                } else {
                    link.href = this.element.dataset.link;
                }
            });
        }

        bindEvents() {
            this.element.addEventListener('click', () => {
                this.modal.classList.add('open-modal');
                this.toggleCloseIcon();
                this.moveVideoToModal(this.video_player, this.modal); 
                this.video_player.classList.add('video-show');
            });
        }
    }
    const elements = document.querySelectorAll('[data-video]');
    elements.forEach((element) => {
        new VideoModalToggle(element);
    });
};
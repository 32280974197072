// eslint-disable-next-line no-unused-vars
export default Search => {

    class SearchTool {
        constructor(logo) {
            this.open = logo;
            this.close = document.querySelector('.close');
            this.modal = document.querySelector('.modal');

            this.form = document.querySelector('.search__form');
            this.label = document.querySelector('.search__form--label');
            this.input = document.querySelector('.search__form--input');
            this.submit_logo = document.querySelector('.search__form--logo');

            this.search = document.querySelector('.search');
            this.header = document.querySelector('.header-wrapper');

            this.status = true;

            this.bindEvents();
        }

        preventHamburgerClick() {
            const hamburger = document.querySelector('.header__hamburger');
            hamburger.classList.toggle('no-click');
        }

        moveSearch(source, destination) {
            var fragment = document.createDocumentFragment();
            fragment.appendChild(source);
            destination.appendChild(fragment);
        }

        showSearch() {
            this.search.classList.toggle('show-search');
        }

        toggleIcons() {
            const toggle = (item) => {
                item.classList.toggle('search-close');
                item.classList.toggle('search-open');
            };

            this.status ? this.modal.classList.add('open-modal') : this.modal.classList.remove('open-modal');
            toggle(this.open);
            toggle(this.close);
        }

        async ajaxCall() {
            const url = window.location.href;
            const data = new FormData(this.form);
            const search_query = await fetch(url, {
                method: 'POST',
                header: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: data
            });

            return await search_query.json();
        }

        toggleInputFocus() {
            if (this.label.classList.contains('label-focus')) {
                this.label.classList.remove('label-focus');
            } else {
                this.label.classList.add('label-focus');
            }
        }

        bindEvents() {

            [this.open, this.close].forEach((logo) => {
                logo.addEventListener('click', () => {
                    if (!logo.classList.contains('product-modal-open')) {
                        this.toggleIcons();
                        this.status ? this.moveSearch(this.search, this.modal) : this.moveSearch(this.search, this.header);
                        this.showSearch();
                        this.preventHamburgerClick();

                        new SearchResults(false).resetResponse();

                    } else {
                        this.status = false;
                        this.toggleIcons();
                        [this.open, this.close].forEach(logo => logo.classList.remove('product-modal-open'));
                        const navigation = document.querySelector('.navigation');
                        for (let item of this.modal.children) {
                            if (item != navigation) {
                                item.remove();
                            }
                        }
                    }
                    this.status = !this.status;
                });
            });

            this.input.addEventListener('focus', () => {
                this.toggleInputFocus();
            });

            this.input.addEventListener('blur', (event) => {
                let search = false;
                if (event.relatedTarget == this.submit_logo) {
                    search = true;
                    this.input.focus();
                    this.toggleInputFocus();
                } else {
                    this.toggleInputFocus();
                }

                search ? null : this.input.value = '';
            });

            this.submit_logo.addEventListener('click', () => {
                this.form.dispatchEvent(new Event('submit'));
            });

            this.form.addEventListener('submit', (event) => {
                event.preventDefault();
                const response = this.ajaxCall();
                response.then((resp) => new SearchResults(resp));
            });
        }
    }

    class SearchResults {
        constructor(response) {
            this.response = response;
            this.redirect = this.response.redirect;
            this.search = this.response.search;

            this.results = document.querySelector('.search__results');
            this.noResult = document.querySelector('.no-results');

            this.resetResponse();
            this.unpackResponse();
        }

        resetResponse() {
            const results = document.querySelectorAll('.search__result__element');
            for (let result of results) {
                if (!result.classList.contains('hide')) {
                    result.remove();
                }
            }
            this.noResult.innerHTML = '';
            this.noResult.classList.add('hide');
        }

        createResponse() {
            if (this.search) {
                const response = this.results.querySelector('.search__result__element');

                for (const item of Object.values(this.search)) {
                    const copy = response.cloneNode(true);
                    const image = copy.querySelector('img');
                    const title = copy.querySelector('.result__title');
                    const link = copy.querySelector('.result__link');
                    let link_a = document.createElement('a');
                    link_a.setAttribute('data-seo-target', '');
                    link.appendChild(link_a);
                    const description = copy.querySelector('.result__description');

                    for (const [key, value] of Object.entries(item)) {
                        if (key == 'image' || key == 'photo') {
                            if (value != false) {
                                image.src = value;
                            }
                        } else if (key == 'description' || key == 'text') {
                            if (value != false) {
                                description.innerHTML = `${value.substring(0, 250)}...`;
                            }
                        } else if (key == 'url') {
                            link_a.href = value;
                        } else {
                            if (value != false) {
                                title.innerHTML = value;
                            }
                        }
                    }
                    copy.classList.toggle('hide');
                    if (!copy.querySelector('.image img').getAttribute('src')) {
                        copy.querySelector('.image img').classList.add('hide');
                    }
                    this.results.appendChild(copy);
                }

            } else {
                this.noResult.innerHTML = "Navré mais votre recherche n'a pas aboutie..!";
                this.noResult.classList.remove('hide');
            }

        }

        unpackResponse() {
            this.redirect ? window.location.href = this.redirect : null;
            this.createResponse();
        }
    }

    const search_logo = document.querySelector('.open');
    new SearchTool(search_logo);

};
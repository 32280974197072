//eslint-disable-next-line no-unused-vars
import Glide from '@glidejs/glide';

//eslint-disable-next-line no-unused-vars
export default Carousel => {

    const galerie = document.querySelector('.galerie');

    var galerie_slider = new Glide(galerie, {
        type: 'carousel',
        perView: 3,
        animationDuration: 750,
        animationTimingFunc: 'ease-in-out',
        breakpoints: {
            1024: {
                perView: 2
            },
            768: {
                perView: 1
            }
        }
    });

    setTimeout(() => {
        galerie_slider.mount();
    }, 50);
};
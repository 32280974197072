import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

//eslint-disable-next-line no-unused-vars
export default ScrollTrigger => {
    const grid = document.querySelectorAll('.grid__item');
    const product = document.querySelectorAll('.collection');
    const news = document.querySelectorAll('.news__container');

    for (let collection of [grid, product, news]) {
        collection.forEach((item) => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    start: 'top 100%',
                    toggleActions: 'play reset play reset'
                },
            }).fromTo(item, {
                opacity: 0,
                x: -100,
            }, {
                opacity: 1,
                x: 0,
                duration: 1,
            });
        });
    }
};